<template>
  <portal :to="PORTAL_FOR_MODAL_SELECTOR">
    <modal
      name="survey-modal"
      :width="400"
      height="auto"
      adaptive
      class="survey-modal"
      :click-to-close="false"
      @opened="modalOpened"
    >
      <div v-if="!iframeLoaded" class="text-center">
        <div class="flex items-center justify-center mx-auto mt-2">
          <span class="mr-2 text-xs capitalize lg:text-sm">{{
            $t("pleaseWait")
          }}</span>
          <div class="loader small"></div>
        </div>
        <button
          class="px-2 py-1 my-2 text-xs font-bold capitalize border rounded-full text-red-dark border-red-dark"
          @click="$emit('on-retry')"
        >
          {{ $t("tryAgain") }}
        </button>
      </div>
      <iframe
        :id="frameId"
        class="w-full h-full"
        :src="iframeSrc"
        style="height: 650px"
      ></iframe>
    </modal>
  </portal>
</template>

<script>
import closeIcon from "@/assets/icon-close-black-v2.svg";
import { SURVEY_FRAME_ID, PORTAL_FOR_MODAL_SELECTOR } from "@/lib/constant";
import { framePostMessage } from "@/helper/frameCommunication";
import { ref } from "@vue/composition-api";
import qs from "qs";
import humps from "humps";
import { selectedCityId } from "@/composable/selectCity";
import { storageSetSurvey } from "@/lib/localStorage";

export default {
  name: "SaveAddressModal",
  setup() {
    const iframeLoaded = ref(false);
    const iframe = ref("");
    const iframeSrc = ref("");

    return {
      iframe,
      frameId: SURVEY_FRAME_ID,
      iframeLoaded,
      iframeSrc,
      closeIcon,
      PORTAL_FOR_MODAL_SELECTOR,
    };
  },
  computed: {
    frameParam() {
      return qs.stringify(
        humps.decamelizeKeys({
          embed: true,
          // debug: true,
          clientType: "web",
          lang: this.$store.state.lang,
        })
      );
    },
  },
  watch: {
    iframeLoaded() {
      console.log("frame loaded");
      this.frameLoadedHandler();
    },
  },
  beforeDestroy() {
    this.stopListenPostMessage();
  },
  methods: {
    modalOpened() {
      this.buildLink();
      this.iframeOnLoad();
      this.startListenPostMessage();
    },
    closeModal() {
      this.$modal.hide("survey-modal");
    },
    buildLink() {
      const domain = this.$store.getters["webConfig/subWebDomain"];
      let url = `${domain}/survey`;
      this.iframeSrc = `${url}?${this.frameParam}`;
    },
    iframeOnLoad() {
      this.iframe = document.getElementById(this.frameId);
      this.iframe.addEventListener("load", () => {
        this.iframeLoaded = true;
      });
    },
    startListenPostMessage() {
      window.addEventListener("message", this.postMessageHandler);
    },
    stopListenPostMessage() {
      window.removeEventListener("message", this.postMessageHandler);
    },
    postMessageHandler(event) {
      const { eventName, payload } = event.data;
      if (eventName === "onHeightChanged") {
        let height = payload.height > 0 ? payload.height : 650;
        this.iframe.style.height = `${height}px`;
        return;
      }

      if (eventName === "onSurveySubmitted") {
        const userId = this.$store.state.user.id || "";
        storageSetSurvey(payload, userId);
        this.$store.dispatch("survey/setSurvey", payload);
        const cityId = payload.cityId;
        if (cityId) {
          selectedCityId.value = cityId;
        }
        this.closeModal();
        return;
      }
    },
    frameLoadedHandler() {
      const message = {
        provider: "hungryhub",
        eventName: "doRenderSurvey",
        payload: {},
      };
      framePostMessage(this.frameId, message);
    },
  },
};
</script>
