import { isAllowLocalStorage } from "@/helper/storagePermissionHelper";

let userProfile = {
  key: "hungryhub_user_profile_v2",
  description: "Saved user profile",
};

function storageSetUserProfile(profile) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(userProfile.key, JSON.stringify(profile));
  }
}

function storageGetUserProfile() {
  if (isAllowLocalStorage()) {
    const profile = localStorage.getItem(userProfile.key);
    if (profile) {
      const parsed = JSON.parse(profile);
      return parsed;
    }
    return null;
  }
  return null;
}

function storageRemoveUserProfile() {
  if (isAllowLocalStorage()) {
    localStorage.removeItem(userProfile.key);
  }
}

let userLocation = {
  key: "hungtyhub_user_location_v2",
  description: "Saved user location based on address search",
};

function storageSetUserLocation(location) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(userLocation.key, JSON.stringify(location));
  }
}

function storageGetUserLocation() {
  if (isAllowLocalStorage()) {
    const location = localStorage.getItem(userLocation.key);
    if (location) {
      const parsed = JSON.parse(location);
      return parsed;
    }
    return null;
  }
  return null;
}

let survey = {
  key: "hungryhub_survey",
  description: "Saved user survey data",
};

function storageSetSurvey(payload, userId) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(`${survey.key}_${userId}`, JSON.stringify(payload));
  }
}

function storageGetSurvey(userId) {
  if (isAllowLocalStorage()) {
    const surveyData = localStorage.getItem(`${survey.key}_${userId}`);
    if (surveyData) {
      const parsed = JSON.parse(surveyData);
      return parsed;
    }
    return null;
  }
  return null;
}

let markHasSendPurchasedEvent = {
  key: "hungryhub_tracked_reservation",
  description: "To mark reservation has benn tracked",
};

function storageSetTrackedResevation(reservationId) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(markHasSendPurchasedEvent.key, reservationId);
  }
}

function storageGetTrackedReservation() {
  if (isAllowLocalStorage()) {
    return localStorage.getItem(markHasSendPurchasedEvent.key);
  }
  return "";
}

let voucherPurchasedEvent = {
  key: "hungryhub_voucher_purchased",
  description: "To mark voucher purchase event has benn tracked",
};

function storageSetVoucherPurchased(purchaseId) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(voucherPurchasedEvent.key, purchaseId);
  }
}

function storageGetVoucherPurchased() {
  if (isAllowLocalStorage()) {
    return localStorage.getItem(voucherPurchasedEvent.key);
  }
  return "";
}

const see3DView = "hungryhub_3d_view_restaurant_id";

function storegeSetSeed3DView(restaurantId) {
  if (isAllowLocalStorage() && restaurantId) {
    localStorage.setItem(see3DView, restaurantId);
  }
}

function storageGetSee3DView() {
  if (isAllowLocalStorage()) {
    return localStorage.getItem(see3DView);
  }
  return "";
}

export {
  storageSetUserProfile,
  storageGetUserProfile,
  storageRemoveUserProfile,
  storageSetUserLocation,
  storageGetUserLocation,
  storageSetTrackedResevation,
  storageGetTrackedReservation,
  storageGetSee3DView,
  storegeSetSeed3DView,
  storageSetVoucherPurchased,
  storageGetVoucherPurchased,
  storageSetSurvey,
  storageGetSurvey,
};
