import { isAllowLocalStorage } from "@/helper/storagePermissionHelper";
import { DEFAULT_LANG } from "@/lib/constant";

const LOCAL_STORAGE_KEY = "hungryhub_lang";
const ACCEPTED_LANG = ["en", "th"];
// return 'en' from 'en-US'
export function getBrowserLang() {
  const browserLang = navigator.language;
  if (browserLang.includes("-")) {
    const split = browserLang.split("-");
    if (split.length == 2) {
      if (ACCEPTED_LANG.includes(split[0])) {
        return split[0];
      }
      return "";
    }
    return "";
  }
  return ACCEPTED_LANG.includes(browserLang) ? browserLang : "";
}

export function setStoredLang(lang: string) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(LOCAL_STORAGE_KEY, lang);
  }
}

export function getStoredLang() {
  if (isAllowLocalStorage()) {
    const lang = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lang) {
      if (ACCEPTED_LANG.includes(lang)) {
        return lang;
      }
      return "";
    }
    return "";
  }
  return "";
}

export function getPreferedLang() {
  const storedLang = getStoredLang();
  const browserLang = getBrowserLang();
  return storedLang || browserLang || DEFAULT_LANG;
}
