import {
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_VERSION,
  INVOLVE_ASIA_CLICK_ID,
} from "@/lib/constant";
import { getFromUserStorage } from "@/helper/userStorage";

function getUTMSource() {
  const defaultUtmSource = "web";
  const utmSource = getFromUserStorage(UTM_SOURCE);
  const utmMedium = getFromUserStorage(UTM_MEDIUM);
  const utmVersion = getFromUserStorage(UTM_VERSION);
  const involveAsiaClickId = getFromUserStorage(INVOLVE_ASIA_CLICK_ID);
  let utm = "";
  if (
    typeof utmVersion === "string" &&
    utmVersion === "v2" &&
    typeof utmMedium === "string" &&
    utmMedium.length
  ) {
    utm = utmMedium;
  } else {
    utm = utmSource;
  }
  if (typeof involveAsiaClickId === "string" && involveAsiaClickId.length > 0) {
    return "InvolveAsia";
  }
  return typeof utm === "string" && utm.length ? utm : defaultUtmSource;
}

export { getUTMSource };
