<template>
  <div class="flex items-center">
    <div class="mr-2 pretty p-svg p-round" :class="{ 'p-bigger': large || '' }">
      <input
        :checked="value"
        type="checkbox"
        :disabled="disabled"
        @change="updateValue($event)"
      />
      <div class="state p-danger">
        <!-- svg path -->
        <svg class="svg svg-icon" viewBox="0 0 20 20">
          <path
            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
            style="stroke: white; fill: white"
          ></path>
        </svg>
        <label for=""></label>
      </div>
    </div>
    <label v-html="label"></label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    large: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true,
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue: function (event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>
