import useHttp from "@/composable/useHttp";
import useReport from "@/composable/useReport";

export interface HomeSectionAPIResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      title: string;
      description: null | string;
      footerDescription: null | string;
      slug: string;
      sectionNumber: string;
    };
  }[];
  success: boolean;
  message: null;
}

export async function getHomeSectionGroupLanding(): Promise<{
  isSuccess: boolean;
  data: HomeSectionAPIResponse["data"];
  message: string;
}> {
  const { isSuccess, data, message, error } = await useHttp({
    url: "/homes/sections.json",
  });
  const parsedResponse = data.data as HomeSectionAPIResponse["data"];
  if (!isSuccess || !parsedResponse.length) {
    useReport({
      level: "error",
      message: error.message,
      errorException: error.detail,
    });
    return {
      isSuccess: false,
      data: [],
      message: error.message,
    };
  }
  return {
    isSuccess: true,
    data: parsedResponse,
    message: message,
  };
}
